import React, {useEffect, useState} from "react";
import Layout from "../components/Layout/Layout";
import {Link, navigate} from "gatsby";
import toast from 'react-hot-toast';

import {ArrowLeftCircleIcon, MagnifyingGlassCircleIcon, XCircleIcon} from '@heroicons/react/24/outline'
import {Footer} from "../components/Footer/Footer";
import {assignSeats, searchUsers} from "../helpers/courses";
import {useSelector} from "react-redux";
import {RootState} from "../reducers/rootReducer";
import {Loader} from "../components/Loader/Loader";

const Students = ({location}: any) => {
  const userName = typeof window !== 'undefined' && localStorage.getItem('name');
  const [users,setUsers] = useState([]);
  const [assigned,setAssigned] = useState<any>([]);
  const [signed,setSigned] = useState(false);
  const [name,setName] = useState("");
  const [courseId,setCourseId] = useState("");
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);

  const [empty,setEmpty] = useState(false);

  const [quantity,setQuantity] = useState(0);

  const [counter,setCounter] = useState(0);

  const [courseName,setCourseName] = useState("");


  const searchingStudents = async () => {
    const filteredUsers = await searchUsers(name,"");

    if (!filteredUsers?.length) {
      setEmpty(true);
    } else {
      setEmpty(false);
    }

    setUsers(filteredUsers);
  }

  const assigningSeats = (item: any) => {
    const assigneUsers = [...assigned];
    let add = false;
    setUsers([]);
    setName("");
    assigneUsers.map(element => {
      if(JSON.stringify(element) === JSON.stringify(item)) {
        add = true;
      }
    });
    if(!add) {
      assigneUsers.push(item);
    }
    const itemsOnLocal = typeof window !== 'undefined' && JSON.parse(localStorage.getItem('usersToSend') || "{}");
    if(JSON.stringify(itemsOnLocal) !== "{}") {
      orderItems(itemsOnLocal,assigneUsers);
    }else {
      const itemsToLocal = [];
      itemsToLocal.push({id: courseId, elements: [...assigneUsers]})
      typeof window !== 'undefined' && localStorage.removeItem('usersToSend');
      typeof window !== 'undefined' && localStorage.setItem('usersToSend', JSON.stringify(itemsToLocal));
    }
    setAssigned(assigneUsers);
  }

  const orderItems = (itemsOnLocal: any,assigneUsers: any) => {
      let newItems = [];
      itemsOnLocal.map( (item: any) => {
        if(item.id.toString() !== courseId.toString()) {
          newItems.push(item);
        }
      });
      newItems.push({id: courseId, elements: [...assigneUsers]})
      typeof window !== 'undefined' && localStorage.removeItem('usersToSend');
      typeof window !== 'undefined' && localStorage.setItem('usersToSend', JSON.stringify(newItems));
  }

  const sendSeats = async () => {
    setSubmitLoader(true)

    let elementsUuid: any = [];

    assigned.map( (item: any) => {
      elementsUuid.push(item.uuid);
    });

    const element = await assignSeats(courseId,elementsUuid);
    console.log('the assigned element: ***** ',element);
    let newItems: any = [];
    const itemsOnLocal = typeof window !== 'undefined' && JSON.parse(localStorage.getItem('usersToSend') || "{}");
    itemsOnLocal.map( (item: any) => {
      if(item.id.toString() !== courseId.toString()) {
        newItems.push(item);
      }
    });
    typeof window !== 'undefined' && localStorage.removeItem('usersToSend');
    typeof window !== 'undefined' && localStorage.setItem('usersToSend', JSON.stringify(newItems));
    setSubmitLoader(false)
    if(element.status) {
      navigate("/courses");
    } else {
      toast.error('Something went wrong, try again.\nSystem message: ' + element.message)
    }
  };

  const removeItem = (index: number) => {
    const assigneUsers = [...assigned];
    const itemsOnLocal = typeof window !== 'undefined' && JSON.parse(localStorage.getItem('usersToSend') || "{}");
    assigneUsers.splice(index,1);
    orderItems(itemsOnLocal,assigneUsers);
    setAssigned(assigneUsers);
  }

  const cancelItems = () => {
    let newItems: any = [];
    const itemsOnLocal = typeof window !== 'undefined' && JSON.parse(localStorage.getItem('usersToSend') || "{}");
    itemsOnLocal.map( (item: any) => {
      if(item.id.toString() !== courseId.toString()) {
        newItems.push(item);
      }
    });
    typeof window !== 'undefined' && localStorage.removeItem('usersToSend');
    typeof window !== 'undefined' && localStorage.setItem('usersToSend', JSON.stringify(newItems));
    setAssigned([]);
    navigate("/courses");
  }

  const loginElement: any = useSelector((state: RootState) => state.loginSlice);

  useEffect( () => {
    if(loginElement.items.status) {
      setSigned(true)
    } else {
      navigate("/")
    }
  },[loginElement]);

  useEffect(() => {
    if(location.state !== null) {
      setCourseName(location.state.elementItem.course.title);
      setQuantity(location.state.elementItem.quantity);
      setCounter(location.state.elementItem.counter);
      setCourseId(location.state.elementItem.courseUuid);
    } else {
      navigate("/courses");
    }
	}, [location])

  useEffect( () => {
      if(courseId !== "")  {
        const itemsOnLocal = typeof window !== 'undefined' && JSON.parse(localStorage.getItem('usersToSend') || "{}");
        if(JSON.stringify(itemsOnLocal) !== "{}") {
          const theItem = itemsOnLocal.filter( (item: any) => item.id.toString() === courseId);
          if(theItem.length) {
            setAssigned(theItem[0].elements)
          }
        }
      }
  },[courseId]);

  useEffect( () => {

    const delayDebounceFn = setTimeout(() => {
      if(name !== "") {
        searchingStudents();
      }
    }, 500)


    return () => clearTimeout(delayDebounceFn)

  },[name]);

  return (
    <Layout signed={signed}>
      <div className="bg-slate-50">
        <div>
          {/* header */}
          {/* <Header isSignIn={signed} /> */}

          <section className="container px-[15px] mx-auto pt-[40px] lg:pt-[60px] pb-[40px] lg:pb-0">
            <div className="lg:flex lg:items-center lg:justify-between pb-10">
              <div className="flex items-center">
                <img src="" alt="" />
                <div className="mb-4 md:mb-0">
                  <div className="flex items-center mb-2">
                    <Link className="flex items-center" to={"/courses"}>
                      <ArrowLeftCircleIcon className="h-6 w-6"/>
                      <span className="ff-cg--medium ml-2">BACK TO COURSES</span>
                    </Link>
                  </div>
                  <h2 className="text-[30px] lg:text-[60px] ff-cg--semibold leading-none">Assign Seats</h2>
                  <p className="text-[18px] lg:text-[30px] ff-cg--semibold">Course: {courseName}</p>
                </div>
              </div>
            </div>
            <div className="grid gap-5 lg:gap-10 lg:grid-cols-12">
              <div className="col-span-12">
                <div className="rounded-xl bg-white shadow-lg h-full p-[30px]">
                  <p className="text-2xl mb-4">Enter user’s email addresses to add them to this course. You have <span className="ff-cg--semibold">{quantity - counter - assigned.length}</span> remaining seats available</p>
                  <div className="mb-6">
                    <div className="w-full">
                      <div className="flex items-center justify-between mb-4">
                        <p className="ff-cg--semibold">Enter email address</p>
                      </div>
                      <div className="flex gap-4 w-full">
                        <div className="shadow-lg flex items-center px-[14px] py-[7px] rounded-2xl w-full cursor-pointer bg-white mb-4 md:mb-0">
                          <MagnifyingGlassCircleIcon className="h-6 w-6 text-[#da1a32] mr-[15px]" />
                          <input style={{"width": "calc(100% - 334px"}} className="ff-cg--semibold placeholder:text-[#000000] p-[10px] focus:outline-none" type="search" value={name} onChange={(e) => setName(e.target.value)} placeholder="Search Students" />
                          {
                            (empty) &&
                            <p className="text-red-600 ff-cg--semibold">User doesn't exists in your organization.</p>
                          }
                        </div>
                        {
                            (empty) &&
                          <button type="submit" className="flex items-center justify-center bg-[#fdbf38] py-[14px] px-[16px] rounded-2xl w-full lg:w-[200px]" onClick={sendSeats}>
                            <Link to={"/add-student"}><span className="ff-cg--semibold">Add as a New User</span></Link>
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                  <div>
                    {
                      (users?.length) ?
                      <>
                        {
                          users.map( (item: any,index) => {
                            return(
                              <div className="bg-white shadow-lg border-l-4 border-solid border-[#da1a32] mb-5 cursor-pointer" key={index} onClick={() => assigningSeats(item)}>
                                <div className="grid gap-5 lg:gap-10 lg:grid-cols-12 p-4">
                                  <div className="col-span-3">
                                    <p className="ff-cg--semibold text-[18px]">Name</p>
                                    <p>{item.firstName} {item.lastName}</p>
                                  </div>
                                  <div className="col-span-4">
                                    <p className="ff-cg--semibold text-[18px]">Email</p>
                                    <p>{item.email}</p>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </> : ""
                    }
                  </div>

                  <div className="relative">
                    {
                        (assigned.length)  ?
                        <div className="flex items-center justify-between">
                          <p className="ff-cg--semibold mb-2">Seats to be Assigned</p>
                        </div>  : ""
                    }
                    <div>
                      {
                        (assigned.length) ?
                        <>
                          {
                            assigned.map( (item:any,index: number) => {
                              return(
                                <div className="bg-white shadow-lg border-l-4 border-solid border-[#da1a32] mb-5" key={index}>
                                  <div className="grid gap-5 lg:gap-10 lg:grid-cols-12 p-4 items-center">
                                    <div className="col-span-3">
                                      <p className="ff-cg--semibold text-[18px]">Name</p>
                                      <p>{item.firstName} {item.lastName}</p>
                                    </div>
                                    <div className="col-span-6">
                                      <p className="ff-cg--semibold text-[18px]">Email</p>
                                      <p>{item.email}</p>
                                    </div>
                                    <div className="col-span-3">
                                      <div className="flex items-center justify-end">
                                        <p className="flex items-center" onClick={ () => removeItem(index)}>
                                          <XCircleIcon className="h-6 w-6"/>
                                          <span className="ff-cg--semibold ml-2">Remove</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </> : ""
                      }
                    </div>
                  </div>

                  <div className="md:flex items-center justify-center gap-4 lg:gap-10 mt-10">
                    <button className="w-full md:w-[200px] flex items-center justify-center border border-[#222222] py-[14px] px-[16px] rounded-2xl mb-4 md:mb-0" onClick={cancelItems}>
                      <span className="ff-cg--semibold">Cancel and Return</span>
                    </button>
                    <button type="submit" className={"flex items-center justify-center bg-[#fdbf38] min-h-[52px] px-[16px] rounded-2xl w-full md:w-[200px] "  + ( submitLoader ? '!bg-[#da1a32] justify-between' : '')} onClick={sendSeats}>
                      <span className={"ff-cg--semibold " + ( submitLoader ? 'text-white' : '' )}>Assign Seats</span>
                      <Loader open={submitLoader} dimension={"8"} min={"8"} wrapClass={''}/>
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </section>

          {/* footer */}
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default Students;
export { Head } from "../components/Layout/Head";
